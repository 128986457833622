* {
  box-sizing: border-box;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: #e6e8e6;
  margin-bottom: 6rem;
}

/* NAVBAR STYLES */

.nav-link {
  text-transform: uppercase;
  border-top: 0.1875rem solid rgba(255,255,255,0);
  margin: 0 2rem 0 2rem;
  color: #e6e8e6;
}

.nav-link:hover {
  border-top: 0.1875rem solid #9fb8ad;
}

/* HEADER STYLES */

.header-wrapper {
  position: relative;
  background: url(pictures/dark-fern.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 45vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* SKILLS STYLES */

#skills .container img {
  height: 5rem;
  margin: 2rem;
}

/* FOOTER STYLES */

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6rem;
}

.footer-nav-link {
  text-transform: uppercase;
  text-decoration: none;
  margin: 2rem;
  color: #e6e8e6;
}

.footer-nav-link:hover {
  color: gray;
}